* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Gudea', sans-serif;
}

html,
body,
#root {
    background-color: black;
    height: 100%;
    color: #E09DFF;
}