.admin-login-container {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    width: 300px;
    justify-content: center;
    margin: 50px auto;
}

.admin-login-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.admin-login-container input[type="text"],
.admin-login-container input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.admin-login-container button {
    background-color: #E09DFF;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
}

.admin-login-container button:hover {
    background-color: #0056b3;
}

.error-message {
    color: #ff0000;
    margin-top: 10px;
}