.return-pesquisa {
    margin: 10px 20px;
}

.return-pesquisa h2 {
    font-size: 30px;
    margin: 20px 0;
}

.return-pesquisa span {
    color: red;
}

.return-pesquisa section {
    margin: 20px 0;
}

.return-pesquisa h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.return-pesquisa ul {
    list-style-type: none;
    padding: 0;
}

.return-pesquisa li {
    margin-bottom: 10px;
}

.return-pesquisa a {
    text-decoration: none;
    color: #E09DFF;
    display: flex;
    align-items: center;
    font-size: 25px;
}

.return-pesquisa img {
    max-width: 250px;
    margin-right: 10px;
    border-radius: 10px;
}