.filmes-container {
    margin: 20px;
    padding: 20px;
    text-align: center;
}

.filmes-container h1 {
    font-size: 34px;
    margin-bottom: 20px;
    text-align: left;
}

.filmes-container span {
    color: red;
}

.filmes-list {
    display: flex;
    flex-wrap: wrap;
}

.filme-item {
    margin: 10px;
    width: calc(25% - 20px);
    box-sizing: border-box;
}

.filme-item img {
    max-width: 100%;
    height: 300px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination button {
    margin: 5px;
    padding: 5px 10px;
    background-color: #E09DFF;
    color: #fff;
    border: none;
    cursor: pointer;
}

.pagination button.current {
    background-color: #555;
}

.pagination button:last-child {
    margin-right: 0;
}