/* Estilização do formulário de edição de série */
.form-container {
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
    margin: 50px auto;
}

.form-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
}

.form-group select {
    height: 40px;
}

.form-group textarea {
    height: 150px;
}

/* Estilização dos botões */
.btn-container {
    text-align: center;
    margin-top: 20px;
}

.btn-container button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #E09DFF;
}


/* Estilização para mensagens de erro ou sucesso */
.message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
}

.message.success {
    background-color: #28a745;
    color: #fff;
}

.message.error {
    background-color: #dc3545;
    color: #fff;
}