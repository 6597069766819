.admin-panel {
    display: flex;
}

.admin-sidebar {
    width: 250px;
    background-color: #333;
    color: #fff;
    padding: 20px;
    height: auto;
}

.admin-sidebar h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.admin-sidebar ul {
    list-style: none;
    padding: 0;
    margin-top: 100px;
}

.admin-sidebar li {
    cursor: pointer;
    padding: 10px 0;
    transition: background-color 0.3s;
    margin-bottom: 50px;
}

.admin-sidebar li:hover {
    background-color: #444;
}

.admin-sidebar .active {
    background-color: #555;
}

.admin-content {
    flex-grow: 1;
    padding: 20px;
    text-align: center;
}

.admin-content h2 {
    font-size: 50px;
    margin-bottom: 20px;
}

.admin-content a {
    color: rgb(0, 253, 0);
    text-decoration: none;
}

.admin-content a:hover {
    text-decoration: underline;
}

.admin-content h3 {
    margin: 30px auto;
}

.admin-content ul {
    list-style: none;
}

.admin-content li {
    border: 1px solid #ccc;
    width: 80%;
    margin: 10px auto;
    text-align: left;
    padding: 8px;
}

.admin-content li button.edit {
    background-color: yellow;
    color: black;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 50px;
}

.admin-content li button.delete {
    background-color: red;
    color: white;
    border: none;
    margin-left: 20px;
    padding: 5px 10px;
    cursor: pointer;
}