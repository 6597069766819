.singleSerie {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.singleSerie img {
    max-width: 300px;
    max-height: 400px;
    margin-right: 20px;
}

.singleSerie .info {
    display: flex;
    flex-direction: column;
}

.singleSerie h1 {
    text-align: center;
    font-size: 60px;
}

.singleSerie span {
    color: #fc5c03;
}

.singleSerie h2 {
    font-size: 45px;
}

.singleSerie h3 {
    font-size: 30px;
}

.singleSerie p {
    font-size: 25px;
}

.singleSerie h1,
.singleSerie h2,
.singleSerie h3,
.singleSerie p {
    margin: 0;
    margin-bottom: 10px;
}

.comentarioSerie {
    display: block;
    margin-top: 20px;
    text-align: left;
    width: 100%;
    border: 1px solid #ccc;
    padding: 15px;
}