.footer {
  background-color: #E09DFF;
  ;
  color: black;
  text-align: center;
  padding: 1em;
}

.social-icons {
  margin-bottom: 1em;
}

.social-icons a {
  display: inline-block;
  margin: 0 10px;
}

.social-icons i {
  font-size: 40px;
  color: black;
}

.footer-text {
  font-size: 13px;
  margin-top: 1em;
}

.footer-text a {
  text-decoration: none;
  color: black;
}

.footer-text a:hover {
  text-decoration: underline;
}