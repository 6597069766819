.latest-news {
  padding: 2em;
  width: 100%;
}

.latest-news span {
  color: red;
  margin-right: 10px;
}

.latest-news h2 {
  font-size: 24px;
  margin-bottom: 1em;

}

.news-container {
  display: flex;
  justify-content: center;
}

.big-news {
  width: 39%;
}

.news-big-item {
  width: 100%;
  padding: 1em;
  background-color: #f4f4f4;
  border-radius: 5px;
  height: 400px;
  text-align: center;
}

.news-big-item img {
  width: 100%;
  height: 90%;
  object-fit: cover;
  border-radius: 10px;
}

.news-big-item a,
.news-item a {
  text-decoration: none;
  color: #E09DFF;
}

.news-big-item a:hover,
.news-item a:hover {
  text-decoration: underline;
  color: red;
}

.news-item {
  width: 100%;
  padding: 1em;
  background-color: #f4f4f4;
  border-radius: 5px;
  margin-left: 10px;
  height: 195px;
}

.small-news {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 10px;
  text-align: center;
}

.news-item img {
  width: 100%;
  height: 80%;
  object-fit: cover;
  border-radius: 10px;
}

.news-item h3 {
  margin-top: 0.5em;
}

.news-item p {
  font-size: 14px;
  color: #555;
}

.latest-reviews {
  padding: 2em;
}

.latest-reviews h2 {
  font-size: 24px;
  margin-bottom: 1em;
}

.latest-reviews span {
  color: red;
  margin-right: 10px;
}

.reviews-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.series-reviews {
  display: flex;
  gap: 1em;
  height: 440px;
}

.movies-reviews {
  display: flex;
  gap: 1em;
  height: 440px;
}

.review-item {
  width: calc(25% - 0.75em);
  padding: 1em;
  background-color: #f4f4f4;
  border-radius: 5px;
  text-align: center;
}

.review-item img {
  max-width: 100%;
  height: 400px;
  border-radius: 5px;
}