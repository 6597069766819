.noticias-container {
    margin: 20px;
    padding: 20px;
}

.noticias-container h1 {
    font-size: 34px;
    margin-bottom: 20px;
    text-align: left;
}

.noticias-container span {
    color: red;
}

.noticias-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.noticia-item {
    margin: 10px;
    width: calc(50% - 10px);
    box-sizing: border-box;
    align-items: center;
}

.noticia-item img {
    max-width: 200px;
    max-height: 200px;
    margin-right: 20px;
    float: left;
}

.noticia-item h2 {
    margin: 50px 0;
    font-size: 25px;
    text-align: left;
}

.noticia-item a {
    text-decoration: none;
    color: #E09DFF;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination button {
    margin: 5px;
    padding: 5px 10px;
    background-color: #E09DFF;
    color: #fff;
    border: none;
    cursor: pointer;
}

.pagination button.current {
    background-color: #555;
}

.pagination button:last-child {
    margin-right: 0;
}