.singleNoticia {
    margin: 30px;
    width: 70%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.singleNoticia h1 {
    font-size: 40px;
    text-align: center;
}

.singleNoticia img {
    margin: 30px auto;
    height: 400px;
}

.singleNoticia h3 {
    font-size: 15px;
    text-align: left;
    font-weight: 100;
}

.singleNoticia p {
    text-align: left;
    margin: 20px 0;
    font-size: 25px;
    font-weight: 100;
}