.singleFilme {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.singleFilme img {
    max-width: 300px;
    max-height: 400px;
    margin-right: 20px;
}

.singleFilme .info {
    display: flex;
    flex-direction: column;
}

.singleFilme h1 {
    text-align: center;
    font-size: 60px;
}

.singleFilme span {
    color: #fc5c03;
}

.singleFilme h2 {
    font-size: 45px;
}

.singleFilme h3 {
    font-size: 30px;
}

.singleFilme p {
    font-size: 25px;
}

.singleFilme h1,
.singleFilme h2,
.singleFilme h3,
.singleFilme p {
    margin: 0;
    margin-bottom: 10px;
}

.comentarioFilme {
    display: block;
    margin-top: 20px;
    text-align: left;
    width: 100%;
    border: 1px solid #ccc;
    padding: 15px;
}