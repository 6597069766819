.header {
  background-image: url('../../img/bgnavbar.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 600px;
  padding: 2em;
}

.container {
  align-items: center;
}

.links {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  font-size: 25px;
  list-style: none;
  align-items: center;
}

.links ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.links li {
  margin-left: 30px;
}

.links li:hover {
  text-decoration: underline;
}

.links a {
  text-decoration: none;
  color: #E09DFF;
  margin-right: 20px;
}

.searchbar {
  display: flex;
  align-items: center;
}

.searchbar i {
  color: gray;
  width: 0px;
  font-size: 15px;
  z-index: 1;
}

.searchbar input {
  width: 25em;
  border-radius: 10px;
  margin-right: 5px;
  border: 1px solid #ccc;
  padding: 5px 20px;
}

.searchbar button {
  padding: 5px 5px;
  border-radius: 10px;
  background-color: #E09DFF;
  color: black;
  border: 1px solid;
  cursor: pointer;
  width: 100px;
}

.logo-container {
  display: flex;
  width: 100%;
  justify-items: center;
  justify-content: center;
  margin-top: 5em;
}

.logo {
  width: 150px;
  height: auto;
}